import APP_UTILITIES from '@/utilities/commonFunctions';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';

@Component({
  components: {
  }
})


export default class FamilyEnagementCard extends Vue {

    public date: string = '';
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    image!: '';

    @Prop()
    BxForTeamAndFamilyText! :'';

    @Prop()
    heading!:'';

    @Prop()
    numbers!:'';

    @Prop()
    theme!:false;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    mounted(){
      const today : Date | string = new Date();
      this.date = APP_UTILITIES.formatShortDate(today.toDateString());
    }

    
}